import { useCallback } from "react";

function useFormatDate() {
  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);

    // Using Intl.DateTimeFormat for a more customizable format
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
  }, []);

  return formatDate;
}

export default useFormatDate;
