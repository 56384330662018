import Api from "config/api";
import { useTranslation } from "react-i18next";
import { notifyError } from "utilites/toastify";

export const GetPosition = async () => {
  const res = await Api.get("api/select-boxes/positions");
  return res.data;
};

export const AddPosition = async (data) => {
  const res = await Api.post("api/select-boxes/positions", data);
  return res.data;
};

export const UpdatedPosition = async (data) => {
  const res = await Api.put(`api/select-boxes/positions/${data.id}`, data);
  return res.data;
};

export const DeletedPosition = async ({ id }) => {
  const res = await Api.delete(`api/select-boxes/positions/${id}`);
  return res.data;
};

