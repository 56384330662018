import { Box, Button, Checkbox, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, CardHeader, Table, Container, Row } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import HttpsIcon from "@mui/icons-material/Https";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { DeleteRoles } from "./api";
import AddModel from "components/Modal/RolesModal";
import PermissionsModal from "components/Modal/PermissionsRolesModal";
import { getRoles } from "../../redux/slices/user.slice";
import DeleteDialouge from "components/Modal/DeleteDialouge";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { notifyError } from "utilites/toastify";
import BreadCrumbs from "components/BreadCrumbs";
import useUserPermissions from "hooks/useUserPermissions";
import EmptyContentMessage from "components/EmptyContentMessage/EmptyContentMessage";
export default function Roles() {
  const { mode } = useSelector((state) => state.theme);
  const [page, setPage] = useState(1);
  const [confirm, setConfirm] = useState({ open: false, id: "" });
  const [permission, setPermission] = useState({ open: false, id: "", data: {} });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { all_roles } = useSelector((state) => state.user);
  const all_permissions = useUserPermissions();

  useEffect(() => {
    dispatch(getRoles(page));
  }, [page]);

  const { data, isError, error, isLoading, isPlaceholderData } = useQuery({
    queryKey: ["roles", { page }],
    queryFn: () => getRoles(page),
  });

  function handleClose() {
    setConfirm({ open: false, id: "" });
    setModal({ open: false, update: false, data: {} });
  }

  const [studentData, setStudentData] = useState({});
  const [modal, setModal] = useState({
    data: {
      name: "",
      user_phone: "",
      position: "",
      type: "parent",
      email: "",
    },
    open: false,
    update: false,
  });

  function handleCheck(role) {
    if (studentData.id == role.id) {
      setStudentData({});
    } else {
      setStudentData(role);
    }
  }

  function openEdit() {
    if (!studentData.id) return notifyError("You must select parent first");
    if (studentData.constant_role) return notifyError("You can't edit constant role");
    setModal({ update: true, open: true, data: studentData });
  }
  function openDelete() {
    if (!studentData.id) return notifyError("You must select parent first");
    setConfirm({ open: true });
  }

  return (
    <>
      <Box sx={{ minHeight: "calc( 100vh - 123px) " }}>
        <Container className="" fluid>
          <BreadCrumbs pageName={t("roles.name")} />
          <Row>
            <div className="col">
              <Card style={{ backgroundColor: mode == "dark" ? "#1F2A37" : "" }}>
                <CardHeader className={`border-0 bg-${mode != "dark" || "transparent"}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <h3 className={`mb-0 text-${mode == "light" ? "black" : "white"}`}>
                        {t("roles.name")}
                      </h3>
                      <Typography
                        sx={{
                          color: "gray",
                          marginBottom: "16px",
                          marginTop: "8px",
                        }}
                        variant="subtitle2"
                      ></Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      {all_permissions.includes("Create roles") && (
                        <Button
                          onClick={() => setModal({ open: true, update: false, data: {} })}
                          className={`${mode == "dark" ? "dark-button-man" : ""}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            display: "flex",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          <AddCircleIcon sx={{ color: "#71DCD4" }} /> Add
                        </Button>
                      )}
                      {all_permissions.includes("Create roles") && (
                        <Button
                          onClick={openEdit}
                          className={`${mode == "dark" ? "dark-button-man" : ""}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            display: "flex",
                            gap: "5px",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          <EditIcon /> Edit
                        </Button>
                      )}
                      {all_permissions.includes("Create roles") && (
                        <Button
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            display: "flex",
                            gap: "5px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                          onClick={openDelete}
                          className={`${mode == "dark" ? "dark-button-man" : ""}`}
                        >
                          <DeleteIcon sx={{ color: "#C81E1E" }} /> Delete
                        </Button>
                      )}
                    </Box>
                  </Box>
                </CardHeader>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : all_roles?.length > 0 ? (
                  <Table className={`align-items-center  table-${mode} table-flush`} responsive>
                    <thead
                      style={{
                        backgroundColor: mode == "dark" ? "#374151" : "#F9FAFB",
                        color: "#6B7280",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px 0px",
                            fontSize: "12px",
                            width: "8px",
                          }}
                        ></th>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("managment.name")}
                        </th>
                        {all_permissions.includes("Update user") && (
                          <th
                            style={{
                              textAlign: "center",
                              padding: "20px ",
                              fontSize: "12px",
                            }}
                            scope="col"
                          >
                            {t("user.permission")}
                          </th>
                        )}
                        {/* {permissions.some(
                          (ele) => ele.name == "Create roles"
                        ) && (
                          <th scope="col" style={{ textAlign: "center" }}>
                            {t("edit")}
                          </th>
                        )}
                        {permissions.some(
                          (ele) => ele.name == "Create roles"
                        ) && (
                          <th scope="col" style={{ textAlign: "center" }}>
                            {t("del")}
                          </th>
                        )} */}
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        backgroundColor: mode == "dark" ? "#1F2A37" : "white",
                      }}
                    >
                      {all_roles?.map((role, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center" }}>
                            <Checkbox
                              onClick={() => handleCheck(role)}
                              checked={studentData?.id === role.id}
                              disabled={role.constant_role}
                            />
                          </td>
                          <th style={{ textAlign: "center" }} scope="row">
                            {role?.name || "-"}
                          </th>
                          {all_permissions.includes("Create roles") && (
                            <td style={{ textAlign: "center" }}>
                              <IconButton
                                onClick={() =>
                                  setPermission({
                                    open: true,
                                    id: role.id,
                                    data: role?.permissions,
                                    name: role.name,
                                  })
                                }
                                sx={{
                                  backgroundColor: "#5e72e4",
                                  color: "white",
                                }}
                              >
                                <HttpsIcon />
                              </IconButton>
                            </td>
                          )}
                          {/* {permissions.some(
                            (ele) => ele.name == "Create roles"
                          ) && (
                            <td
                              style={{
                                textAlign: "center",
                                display: "felx",
                                justifyContent: "center",
                              }}
                            >
                              <AddModel
                                input={student}
                                update={true}
                                page={page}
                                id={student?.id}
                                data={student}
                              />
                            </td>
                          )}
                          {permissions.some(
                            (ele) => ele.name == "Create roles"
                          ) && (
                            <td>
                              <IconButton
                                disabled={student?.name == "school"}
                                onClick={() =>
                                  setConfirm({ open: true, id: student?.id })
                                }
                                sx={{
                                  backgroundColor: "red",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  margin: "auto",
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>
                          )} */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <EmptyContentMessage
                    onClick={() => {
                      if (all_permissions?.includes("Create roles")) {
                        setModal({ open: true, update: false, data: {} });
                      } else {
                        notifyError("You don't have permission to add.");
                      }
                    }}
                    buttonText={"Add"}
                  />
                )}
                {/* </nav>
                </CardFooter> */}
              </Card>
            </div>
          </Row>
        </Container>
      </Box>
      <DeleteDialouge
        open={confirm.open}
        handleClose={handleClose}
        fun={DeleteRoles}
        id={studentData.id}
        page={page}
        update={getRoles}
        msg={t("user.deleteRole")}
      />
      <PermissionsModal
        name={permission.name}
        open={permission.open}
        handleClose={() => setPermission({ open: false, id: "", data: {} })}
        data={permission.data}
        id={permission.id}
      />

      <AddModel
        input={modal.data}
        update={modal.update}
        page={page}
        id={modal?.data?.id}
        data={modal.data}
        open={modal.open}
        handleClose={handleClose}
      />
    </>
  );
}
