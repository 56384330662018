import Api from "config/api";
import toast from "react-hot-toast";

export const GetReport = async (query, page) => {
  const res = await Api.get(`api/reports?${query}&page=${page}`);
  return res.data;
};

const notifyPromise = (promise, statusMessages = {}) => {
  return toast.promise(promise, {
    loading: statusMessages?.loading || "Please wait while the file is being prepared...",
    success: statusMessages?.success || "The file is ready for download.",
    error: statusMessages?.error || "An error occurred while preparing the file.",
  });
};

export const ExportAs = async (params = {}, statusMessages = {}) => {
  const res = await notifyPromise(
    Api.get("api/reports/export", { params, responseType: "blob" }),
    statusMessages
  )
    .then((response) => {
      // Create a URL for the blob data
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary anchor element for download
      const link = document.createElement("a");
      link.href = url;

      // Set the file name for the download
      link.setAttribute("download", `Reports.${params.export_type}`); // Adjust filename and extension

      // Append the anchor to the document body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up: remove the anchor and revoke the object URL
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      return response;
    })
    .catch((error) => {
      console.error("Error downloading the file:", error);
    });
  return res.data;
};
