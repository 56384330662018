import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Api, { handleApiError } from "../../config/api";
import { notifyError, notifySuccess } from "../../utilites/toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// eslint-disable-next-line react/prop-types
export default function ConfirmationDialoge({
  open,
  handleClose,
  id,
  fun,
  page,
  update,
  keys,
  name
}) {
  const { t } = useTranslation();
  const { direction, mode } = useSelector((state) => state.theme);
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: fun,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [keys],
      });
      notifySuccess(`${name} Deleted`);
      handleClose();
   
    },
    onError: (err) => {
      notifyError("Something wrong happened");
      handleClose();
    },
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        //You can copy the code below in your theme

        "& .MuiPaper-root": {
          background: `${mode == "dark" ? "#172B4D" : "white"}`,
        },
        "& .MuiBackdrop-root": {},
      }}
      dir={direction}
    >
      <DialogTitle
        sx={{
          fontWeight: "500",
          fontSize: "1.3rem ",
          color: `${mode == "dark" ? "#fff" : "#000"}`,
        }}
      >
        {t("deleteModel.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{ fontWeight: "500", fontSize: "1.2rem " }}
        >
          {t("deleteModel.confirm")}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Button
          variant="outlined"
          sx={{ fontWeight: "500", fontSize: "1.1rem" }}
          onClick={handleClose}
          color="info"
        >
          {t("deleteModel.close")}
        </Button>
        <Button
          variant="contained"
          sx={{ fontWeight: "500", fontSize: "1.1rem" }}
          onClick={() => mutate({ id })}
          color="error"
        >
          {t("del")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
