import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../css/index.css";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "components/BreadCrumbs";
import { useSelector } from "react-redux";
import useThemeMode from "hooks/useThemeMode";
import useUserPermissions from "hooks/useUserPermissions";
import useFormatDate from "hooks/useFormatDate";
import { useQuery } from "@tanstack/react-query";
import { notifyError } from "utilites/toastify";
import { ExportAs, GetReport } from "./api";
import dayjs from "dayjs";
import { Card, CardHeader, Table, Row } from "reactstrap";
import { DatePicker } from "@mui/x-date-pickers";
import { MoreHoriz } from "@mui/icons-material";
import GlobalModal from "components/GlobalModal/GlobalModal";
import EmptyContentMessage from "../../components/EmptyContentMessage/EmptyContentMessage";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";

const Reports = () => {
  const [page, setPage] = useState(1);
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [status, setStatus] = useState("");
  const [toDate, setToDate] = useState(null);
  const [userCard, setUserCard] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [query, setQuery] = useState(`per_page=10`);
  const [reportDetails, setReportDetails] = useState({});

  const formatDate = useFormatDate();
  const { t, i18n } = useTranslation();
  const { mode } = useSelector((state) => state.theme);
  const themeMode = useThemeMode(mode);
  const all_permissions = useUserPermissions();

  const open = Boolean(anchorEl);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["rereport", { query, page }],
    queryFn: () => GetReport(query, page),
    onError: (err) => {
      const errors = err.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          notifyError(errors[key]);
        }
      }
    },
  });

  const handleShowDetails = (data) => {
    setReportDetails(data);
    setOpenModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const isClearButton = e.nativeEvent.submitter.getAttribute("data-clear-filters") === "true";

    // Extract and sanitize form values
    let userCardNumber = userCard;
    let selectedType = type;
    let selectedStatus = status;
    let to =
      formData
        .get("to")
        ?.replace(/[\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "")
        .trim() || "";
    let from =
      formData
        .get("from")
        ?.replace(/[\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "")
        .trim() || "";

    // Validate date range
    if (new Date(from) >= new Date(to)) {
      notifyError("The from date must be earlier than the to date.");
      return;
    }

    if (isClearButton) {
      setUserCard("");
      setType("");
      setStatus("");
      setFromDate(null);
      setToDate(null);
      userCardNumber = "";
      selectedType = "";
      selectedStatus = "";
      to = "";
      from = "";
    }

    // Build query parameters
    const queryParams = new URLSearchParams();
    if (userCardNumber) queryParams.append("card_number", userCardNumber);
    if (from) queryParams.append("from_date", `${dayjs(from).format("YYYY-MM-DD")} 00:00:00`);
    if (to) queryParams.append("to_date", `${dayjs(to).format("YYYY-MM-DD")} 00:00:00`);
    if (selectedType) queryParams.append("type", selectedType);
    if (selectedStatus) queryParams.append("status", selectedStatus);
    queryParams.append("per_page", "10");

    // Set the query
    setQuery(queryParams.toString());
  };

  const statusMessages = {
    loading: t("report.export_files_loading"),
    success: t("report.export_files_success"),
    error: t("report.export_files_error"),
  };

  const handleExporting = async (export_type) => {
    setAnchorEl(null);

    const params = { export_type };

    if (userCard) params["card_number"] = userCard;
    if (fromDate) params["from_date"] = `${dayjs(fromDate).format("YYYY-MM-DD")}} 00:00:00`;
    if (fromDate) params["to_date"] = `${dayjs(toDate).format("YYYY-MM-DD")}} 00:00:00`;
    if (type) params.type = type;
    if (status) params.status = status;

    await ExportAs(params, statusMessages);
  };

  const statuses_colors = {
    pending: "warning",
    paid: "success",
    failed: "danger",
    refunded: "info",
    reversed: "info",
    cancelled: "danger",
    approved: "success",
    declined: "danger",
  };

  useEffect(() => {
    const reformattedTypes = [];
    const formattedStatuses = [];

    for (const key in data?.types) {
      reformattedTypes.push({
        name: data?.types[key],
        value: key,
      });
    }

    for (const key in data?.statuses) {
      formattedStatuses.push({
        name: data?.statuses[key],
        value: key,
      });
    }

    setTypes(reformattedTypes);
    setStatuses(formattedStatuses);
  }, [data]);

  return (
    <Box minHeight="89.5vh" sx={{ padding: "36px" }}>
      <BreadCrumbs pageName={i18n.language === "en" ? "Reports" : "التقارير"} />
      <Box
        sx={{
          borderRadius: "16px",
          width: "100%",
          typography: "body1",
          backgroundColor: themeMode === "dark" ? "rgb(31, 42, 55)" : "white",
          padding: "24px",
        }}
      >
        <Typography
          fontWeight="bold"
          variant="h6"
          sx={{
            textAlign: "start",
            color: themeMode === "dark" ? "#fff" : "#000",
          }}
        >
          {i18n.language === "en" ? "Reports" : "التقارير"}
        </Typography>
        <Typography
          sx={{
            color: "gray",
            marginBottom: "16px",
            marginTop: "8px",
            textAlign: "start",
          }}
          variant="subtitle2"
        >
          {i18n.language === "en"
            ? "Review recharge and deduction periodic records"
            : "مراجعة السجل الدوري لإعادة الشحن والخصم للمستخدمين"}
        </Typography>
        <Box sx={{ minHeight: "calc( 100vh - 123px)" }}>
          {/* Page content */}

          <div className="p-0">
            <Row>
              <div className="col">
                <Card
                  style={{
                    backgroundColor: mode === "dark" ? "rgb(31, 42, 55)" : "white",
                  }}
                >
                  <CardHeader className={`border-0 bg-${mode !== "dark" || "transparent"}`}>
                    <form onSubmit={handleSubmit}>
                      <Stack gap={1} direction="row" justifyContent="center">
                        {/* Search user card */}
                        <Box sx={{ width: "100%", display: "grid" }}>
                          <label>{t("managment.stCard")}</label>
                          <TextField
                            sx={{ width: "100%" }}
                            variant="outlined"
                            name="user_card"
                            inputProps={{ type: "number" }}
                            value={userCard}
                            onChange={(e) => setUserCard(e.target.value)}
                          />
                        </Box>

                        {/* Filter by date from */}
                        <Box sx={{ width: "100%", display: "grid" }}>
                          <label>{t("report.from")}</label>
                          <DatePicker
                            sx={{ width: "100%" }}
                            name="from"
                            slotProps={{
                              textField: {
                                required: false,
                              },
                            }}
                            value={fromDate}
                            onChange={(newValue) => setFromDate(newValue)}
                          />
                        </Box>

                        {/* Filter by date To */}
                        <Box sx={{ width: "100%", display: "grid" }}>
                          <label>{t("report.to")}</label>
                          <DatePicker
                            sx={{ width: "100%" }}
                            name="to"
                            slotProps={{
                              textField: {
                                required: false,
                              },
                            }}
                            value={toDate}
                            onChange={(newValue) => setToDate(newValue)}
                          />
                        </Box>
                      </Stack>

                      <Stack gap={1} direction="row" justifyContent="center" mt={2}>
                        {/* Select Type */}
                        <Box sx={{ width: "100%", display: "grid" }}>
                          <FormControl>
                            <InputLabel id="chose_type">{t("report.type")}</InputLabel>
                            <Select
                              labelId="chose_type"
                              id="type_select"
                              value={type}
                              label="Type"
                              onChange={(e) => setType(e.target.value)}
                              disabled={isLoading}
                            >
                              {types.map((type) => (
                                <MenuItem value={type.value} key={type.name}>
                                  {t("report." + type.name)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        {/* Select Status */}
                        <Box sx={{ width: "100%", display: "grid" }}>
                          <FormControl>
                            <InputLabel id="chose_status">{t("report.status")}</InputLabel>
                            <Select
                              labelId="chose_status"
                              id="select_status"
                              value={status}
                              label="Status"
                              onChange={(e) => setStatus(e.target.value)}
                              disabled={isLoading}
                            >
                              {statuses.map((status) => (
                                <MenuItem value={status.value} key={status.name}>
                                  {t("report." + status.name)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Stack>

                      <Stack
                        gap={1}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"end"}
                        mt={2}
                      >
                        {/* Export options */}
                        <Box sx={{ marginInlineEnd: "auto" }}>
                          <Button
                            type="button"
                            id="export-as"
                            aria-controls={open ? "export-options-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            variant="outlined"
                            sx={{ textTransform: "capitalize", padding: "8px 20px" }}
                          >
                            <SimCardDownloadOutlinedIcon /> {t("report.export")}
                          </Button>
                          <Menu
                            id="export-options-menu"
                            aria-labelledby="export-as"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem onClick={() => handleExporting("xlsx")}>
                              {t("report.export_as_xlsx")}
                            </MenuItem>
                            <MenuItem onClick={() => handleExporting("csv")}>
                              {t("report.export_as_csv")}
                            </MenuItem>
                          </Menu>
                        </Box>

                        {/* Clear all filters */}
                        <Button
                          variant="outlined"
                          type="submit"
                          sx={{
                            padding: "8px 25px",
                            textTransform: "capitalize",
                            alignSelf: "flex-end",
                          }}
                          data-clear-filters="true"
                        >
                          {t("report.clear_filters")}
                        </Button>

                        {/* Apply filters */}
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            padding: "8px 25px",
                            textTransform: "capitalize",
                            alignSelf: "flex-end",
                          }}
                        >
                          {t("report.apply_filters")}
                        </Button>
                      </Stack>
                    </form>
                  </CardHeader>
                  {isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  ) : isError ? (
                    <Typography
                      fontWeight="bold"
                      variant="h3"
                      sx={{
                        textAlign: "center",
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                    >
                      {error?.response?.data?.message || error?.message}
                    </Typography>
                  ) : data?.data?.data?.length > 0 ? (
                    <>
                      <Table className={`align-items-center table-${mode}`} responsive>
                        <thead
                          style={{
                            backgroundColor: mode === "dark" ? "rgb(55, 65, 81)" : "#F9FAFB",
                            color: "#6B7280",
                            width: "100%",
                          }}
                        >
                          <tr>
                            {/* Order ID */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.order_number")}
                            </th>

                            {/* Status */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.status")}
                            </th>

                            {/* Amount */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.Amount")}
                            </th>

                            {/* User Card  */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.UserCard")}
                            </th>

                            {/* User Balance */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.user_balance")}
                            </th>

                            {/* Order by */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.order_by")}
                            </th>

                            {/* Machine */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.machine")}
                            </th>

                            {/* Consume time */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.transaction_date")}
                            </th>

                            {/* Show details */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.show_details")}
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            backgroundColor: mode === "dark" ? "rgb(31, 42, 55)" : "white",
                            width: "100%",
                          }}
                        >
                          {all_permissions?.includes("Charge status") &&
                            data?.data?.data?.map((report, index) => (
                              <tr key={index}>
                                <td style={{ textAlign: "center" }}>{report?.order_id}</td>
                                <td
                                  style={{ textAlign: "center" }}
                                  className={"bg-" + statuses_colors[report?.status]}
                                >
                                  {t("report." + report?.status)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {report?.amount
                                    ? i18n.language === "en"
                                      ? `${report.amount} SAR`
                                      : `${report.amount} ريال`
                                    : i18n.language === "en"
                                    ? "0 SAR"
                                    : "0 ريال"}
                                </td>
                                <td style={{ textAlign: "center" }}>{report?.user?.user_card}</td>
                                <td style={{ textAlign: "center" }}>
                                  {report?.user_balance
                                    ? i18n.language === "en"
                                      ? `${report.user_balance} SAR`
                                      : `${report.user_balance} ريال`
                                    : i18n.language === "en"
                                    ? "0 SAR"
                                    : "0 ريال"}
                                </td>

                                {/* Order by */}
                                <td style={{ textAlign: "center" }}>
                                  {report?.action_by?.email ? (
                                    <a href={`mailto:${report?.action_by?.email}`}>
                                      {report?.action_by?.name}
                                    </a>
                                  ) : (
                                    report?.action_by?.name || "-"
                                  )}
                                </td>

                                {/* Machine */}
                                <td style={{ textAlign: "center" }}>
                                  {report?.machine ? (
                                    <>
                                      <span>{report?.machine?.name}</span>
                                      <br />
                                      <span className="text-danger">{report?.machine?.code}</span>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>

                                {/* Transaction Date */}
                                <td style={{ textAlign: "center" }}>
                                  {formatDate(report?.completedTime || report?.created_at)}
                                </td>

                                {/* Show details */}
                                <td style={{ textAlign: "center" }}>
                                  <Tooltip title={t("report.show_details")}>
                                    <IconButton
                                      onClick={() => handleShowDetails(report)}
                                      size="small"
                                      color="primary"
                                    >
                                      <MoreHoriz />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    <EmptyContentMessage buttonText={false} />
                  )}

                  <Stack
                    direction="row"
                    sx={{
                      margin: "10px 0",
                      display: data?.data?.data?.length > 0 ? "flex" : "none",
                    }}
                    gap={2}
                    justifyContent={"center"}
                  >
                    <Pagination
                      count={data?.data?.last_page || 1}
                      shape="rounded"
                      value={page}
                      disabled={isLoading || isError}
                      onChange={(e, value) => setPage(value)}
                    />
                  </Stack>
                </Card>
              </div>
            </Row>
          </div>
        </Box>
      </Box>

      {/* Report information */}
      <GlobalModal
        modalHeaderTypography={t("report.report_details")}
        open={openModal}
        maxWidth="sm"
        handleClose={() => {
          setOpenModal(false);
        }}
      >
        <div style={{ textAlign: "start" }}>
          {/* Order Number */}
          <p className="mb-3 font-weight-300">
            {t("report.order_number")} :{" "}
            <span className="text-gray">{reportDetails?.order_id} </span>
          </p>

          {/* Amount */}
          <p className="mb-3 font-weight-300">
            {t("report.Amount")} :{" "}
            <span className="text-gray">
              {reportDetails?.amount
                ? i18n.language === "en"
                  ? `${reportDetails.amount} SAR`
                  : `${reportDetails.amount} ريال`
                : i18n.language === "en"
                ? "0 SAR"
                : "0 ريال"}
            </span>
          </p>

          {/* User card */}
          <p className="mb-3 font-weight-300">
            {t("report.UserCard")} :{" "}
            <span className="text-gray">{reportDetails?.user?.user_card}</span>
          </p>

          {/* User Balance */}
          <p className="mb-3 font-weight-300">
            {t("report.user_balance")} :{" "}
            <span className="text-gray">
              {reportDetails?.user_balance
                ? i18n.language === "en"
                  ? `${reportDetails.user_balance} SAR`
                  : `${reportDetails.user_balance} ريال`
                : i18n.language === "en"
                ? "0 SAR"
                : "0 ريال"}
            </span>
          </p>

          {/* Order by */}
          <p className="mb-3 font-weight-300">
            {t("report.order_by")}:{" "}
            <span className="text-gray">
              {reportDetails?.action_by?.email ? (
                <a href={`mailto:${reportDetails?.action_by?.email}`}>
                  {reportDetails?.action_by?.name}
                </a>
              ) : (
                reportDetails?.action_by?.name || "-"
              )}
            </span>
          </p>

          {/* Machine */}
          <p className="mb-3 font-weight-300">
            {t("report.machine")} :{" "}
            <span className="text-gray">
              {reportDetails?.machine ? (
                <>
                  <span>{reportDetails?.machine?.name}</span> -{" "}
                  <span className="text-danger">{reportDetails?.machine?.code}</span>
                </>
              ) : (
                "-"
              )}
            </span>
          </p>

          {/* Consume time */}
          <p className="mb-3 font-weight-300">
            {t("report.transaction_date")} :{" "}
            <span className="text-gray">{formatDate(reportDetails?.completedTime || "00")}</span>
          </p>

          {/* status */}
          <p className="mb-3 font-weight-300">
            {t("report.status")} : <span className="text-gray">{reportDetails?.status || "-"}</span>{" "}
          </p>

          {/* type */}
          <p className="mb-3 font-weight-300">
            {t("report.type")} : <span className="text-gray">{reportDetails?.type || "-"}</span>{" "}
          </p>

          {/* note */}
          <p className="mb-3 font-weight-300">
            {t("report.note")} : <span className="text-gray">{reportDetails?.note || "-"}</span>{" "}
          </p>

          {/* fees */}
          <p className="mb-3 font-weight-300">
            {t("report.fees")} :{" "}
            <span className="text-gray">
              {reportDetails?.fee
                ? i18n.language === "en"
                  ? `${reportDetails.fee} SAR`
                  : `${reportDetails.fee} ريال`
                : i18n.language === "en"
                ? "0 SAR"
                : "0 ريال"}
            </span>
          </p>

          {/* payment Id */}
          <p className="mb-3 font-weight-300">
            {t("report.payment_id")}:{" "}
            <span className="text-gray">{reportDetails?.payment_id || "-"}</span>{" "}
          </p>
        </div>
      </GlobalModal>
    </Box>
  );
};
export default Reports;
