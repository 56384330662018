import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  InputAdornment,

} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import axios from "axios";
import Api, { handleApiError } from "../../config/api";
import { notifyError, notifySuccess } from "../../utilites/toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AddParent, UpdateParent } from "../../pages/ParentsManegment/api";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getParents } from "../../redux/slices/user.slice";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "400px", sm: "500px", md: "700px", lg: "900px" },
  bgcolor: "background.paper",
  border: "1px solid var(--primary)",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  color: "#fff",
};
const AddModel = ({ input, update, page, id, data, open, handleClose }) => {
  const { direction, mode } = useSelector((state) => state.theme);
  const dispatch = useDispatch()
  const { parents, parentCount, permissions } = useSelector((state) => state.user)

  const [studentInput, setStudentInput] = useState(input);
  useEffect(() => {
    setStudentInput(input)
  }, [input]);
  const queryClient = useQueryClient();
  const { mutate: mutateAdd } = useMutation({
    mutationFn: AddParent,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["User"],
      });
      dispatch(getParents())
      notifySuccess(i18n.language == "en" ? "Parent data added" : "تم اضافة بيانات ولي الامر");
      handleClose()
    },
    onError: (err) => {
      if (err.response) {
        const status = err.response.status;
        const errorCode = err.code || "ERR_UNKNOWN";
        const errorMessage = err.message || "An unknown error occurred";

        if (status === 422) {
          const errors = err.response.data.errors;
          const errorMessages = [];

          if (errors.email) {
            errorMessages.push(`Email: ${errors.email.join(', ')}`);
          }
          if (errors.user_phone) {
            errorMessages.push(`User Phone: ${errors.user_phone.join(', ')}`);
          }

          errorMessages.forEach(message => {
            notifyError(message)
          });
        }
      } else {
        // console.log("Error", err.message);
      }
    }

  });
  const { mutate: mutateUpdate } = useMutation({
    mutationFn: UpdateParent,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["student"],
      });
      notifySuccess(i18n.language == "en" ? "Parent data updated" : "تم تدل بيانات ولي الامر");
      handleClose()
      dispatch(getParents())
    },
    onError: (err) => {
      if (err.response) {
        const status = err.response.status;
        const errorCode = err.code || "ERR_UNKNOWN";
        const errorMessage = err.message || "An unknown error occurred";

        if (status === 422) {
          const errors = err.response.data.errors;
          const errorMessages = [];

          if (errors.email) {
            errorMessages.push(`Email: ${errors.email.join(', ')}`);
          }
          if (errors.user_phone) {
            errorMessages.push(`User Phone: ${errors.user_phone.join(', ')}`);
          }
          if (errors.name) {
            errorMessages.push(`Name: ${errors.name.join(', ')}`);
          }

          errorMessages.forEach(message => {
            notifyError(message)
          });
        }
      } else {
        // console.log("Error", err.message);
      }
    }
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    if (studentInput.password && (studentInput.password !== studentInput.confirmPass)) { return notifyError(t("user.passErr")) }

    if (update) {
      // let dublicatedemail = parents.find((ele) => ele.email == studentInput.email && ele.id != studentInput.id)

      // if (dublicatedPhone) return notifyError(i18n.language == "en" ? "Phone number is already taken" : "رقم الجوال مستخدم بالفعل")
      // if (dublicatedemail) return notifyError(i18n.language == "en" ? "Email address is already taken" : "البريد الالكتروني مستخدم بالفعل")


      mutateUpdate({ ...studentInput, id })
    } else {
      // let dublicatedPhone = parents.find((ele) => ele.user_phone == studentInput.user_phone)
      // let dublicatedemail = parents.find((ele) => ele.email == studentInput.email)

      // if (dublicatedPhone) return notifyError(i18n.language == "en" ? "Phone number is already taken" : "رقم الجوال مستخدم بالفعل")
      // if (dublicatedemail) return notifyError(i18n.language == "en" ? "Email address is already taken" : "البريد الالكتروني مستخدم بالفعل")
      mutateAdd({ ...studentInput, type: "parent" });
    }
  }

  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const textFieldStyles = {
    "& .MuiInputBase-input": {
      color: mode === "dark" ? "#fff" : "grey",
    },
    "& .MuiInputLabel-root": {
      color: mode === "dark" ? "#fff" : "grey",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: mode === "dark" ? "#fff" : "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: mode === "dark" ? "#bbb" : "grey",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: mode === "dark" ? "#bbb" : "grey",
    },
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (

    <div style={{ textAlign: "right" }}>
      <Dialog
        // fullScreen
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        sx={{
          //You can copy the code below in your theme

          "& .MuiPaper-root": {
            background: `${mode == "dark" ? "#172B4D" : "white"}`,
          },
          "& .MuiBackdrop-root": {},
        }}
        dir={direction}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: theme.palette.text.main }}>
            {update ? t("user.updateParent") : t("user.addParent")}
          </Typography>{" "}
          <IconButton onClick={handleClose} style={{ float: "right" }}>
            <CloseIcon color="primary"></CloseIcon>
          </IconButton>{" "}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} >
            <Grid sx={{ padding: "10px" }} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t("managment.name")}
                  name="name"
                  required
                  value={studentInput.name || ""}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t("managment.email")}
                  name="email"
                  required
                  autoComplete="email"
                  value={studentInput.email || ""}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  fullWidth
                  variant="outlined"
                  label={t("managment.phone")}
                  name="user_phone"
                  disabled={update}
                  value={studentInput.user_phone || ""}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t("user.password")}
                  name="password"
                  value={studentInput.password || ""}
                  onChange={handleInputChange}
                  type={showPassword ? 'text' : 'password'}
                  required={!update}
                    autoComplete="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Confirm Password"
                  name="confirmPass"
                  value={studentInput.confirmPass || ""}
                  onChange={handleInputChange}
                  type={showConfirmPassword ? 'text' : 'password'}
                  required={!update}
                  autoComplete="confirm-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={toggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1AE584",
                    transition: ".4s",
                    "&:hover": {
                      backgroundColor: "#1AE584",
                      opacity: ".8",
                    },
                  }}
                  variant="contained"
                  type="submit"
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddModel;
