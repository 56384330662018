import Api from "config/api";

export const ResetRequest = async (email) => {
  try {
    const request = await Api.post("/api/users/password/reset/request", {
      email: email,
    });
    // console.log("request: ", request);
    return request;
  } catch (error) {
    return error.response;
  }
};
