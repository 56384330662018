import ReactDOM from "react-dom/client";
import "./css/index.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";
import "./config/translationConfig";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "./redux/store";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const persistor = persistStore(store);
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </LocalizationProvider>
    </BrowserRouter>
  </>
);
