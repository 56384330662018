import React, { useEffect, useState } from "react";
import styles from "./NewPassword.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { UpdatePasswordRequest } from "./api";
import { notifySuccess } from "utilites/toastify";
import { notifyError } from "utilites/toastify";
import { CircularProgress } from "@mui/material";

function NewPassword() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handelSubmit = async (e) => {
    e.preventDefault();

    const fromData = new FormData(e.target);
    fromData.append("email", location.state.email);
    fromData.append("verification_token", location.state.verification_token);
    const new_password = fromData.get("new_password");
    const new_password_confirmation = fromData.get("new_password_confirmation");

    // Check if the two new pass words are the same
    if (new_password !== new_password_confirmation) {
      setError("Passwords do not match.");
      return;
    }

    // Reset error if new password is valid
    setError("");
    setLoading(true);

    const res = await UpdatePasswordRequest(fromData);
    console.log(res);

    if (res.status === 200) {
      setLoading(false);
      notifySuccess(`${res?.data?.message}`);

      navigate("/auth/login", { replace: true });
      return;
    } else if (res?.status === 422) {
      setError(res?.data?.errors);
      setLoading(false);
      return;
    } else if (res?.status === 500) {
      notifyError("Ops something went wrong internal server error 500!");
      setLoading(false);
      return;
    } else {
      notifyError(res?.data?.error);
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    if (!location.state?.email || !location.state?.verification_token) {
      // Redirect to first step if no email is present in state
      navigate("/auth/forgot-password", { replace: true });
    }
  }, [location.state, navigate]);

  return (
    <div className={styles.card}>
      <h1>Create new password</h1>
      <p className={`mt-1 mb-4 ${styles.message}`}>
        Please enter the new password to complete the reset.
      </p>
      <form action="" className="" onSubmit={handelSubmit}>
        <div className="mb-2">
          <label htmlFor="user_password" className={("d-block", styles.password_label)}>
            Password
          </label>
          <input
            type="password"
            name="new_password"
            id="user_password"
            autoComplete="new-password"
            className={`mb-1 d-block ${styles.user_password}`}
            placeholder="new password"
            required
            disabled={loading}
          />
          {error && <span className="text-danger text-sm">{error}</span>}
        </div>

        <div className="mb-4">
          <label htmlFor="user_confirm_password" className={("d-block", styles.password_label)}>
            Confirm
          </label>
          <input
            type="password"
            name="new_password_confirmation"
            id="user_confirm_password"
            autoComplete="new-password"
            className={`mb-1 d-block ${styles.user_password}`}
            placeholder="new password"
            required
            disabled={loading}
          />
          {error && <span className="text-danger text-sm">{error}</span>}
        </div>
        <button type="submit" className={`py-2 px-3 text-white w-100  ${styles.send_button}`}>
          {loading ? <CircularProgress size={25} /> : " Reset password"}
        </button>
      </form>
    </div>
  );
}

export default NewPassword;
