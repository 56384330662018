import { useSelector } from "react-redux";

function useUserPermissions() {
  const { all_permissions } = useSelector((state) => state.user);
  const all_user_permissions = [...all_permissions] || [];

  return all_user_permissions;
}

export default useUserPermissions;
