import Api from "config/api";

export const GetBranches = async (page) => {
  try {
    const res = await Api.get("api/branches", {
      params: {
        per_page: 10,
        page: page,
      },
    });
    return res.data;
  } catch (error) {
    // console.error("Error fetching ser data:", error);
    return error;
  }
};

export const AddBranch = async (data) => {
  const res = await Api.post("api/branches/store", data);
  return res.data;
};

export const UpdateBranch = async (data) => {
  const res = await Api.post(`api/branches/update/${data.id}`, data.formData);
  return res.data;
};

export const DeleteBranch = async ({ id }) => {
  const res = await Api.delete(`api/branches/delete/${id}`);
  return res.data;
};

export const GetAllManagers = async () => {
  try {
    const res = await Api.get("api/branches/managers");
    return res.data;
  } catch (error) {
    return error;
  }
};
