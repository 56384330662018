import { useMutation } from "@tanstack/react-query";
import Api from "config/api";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { notifyError, notifySuccess } from "utilites/toastify";
import logo from "../../assets/img/brand/Asset.png";
import { loginUser } from "../../redux/slices/user.slice";
import "./login.css";

const loginStudent = async (data) => {
  const res = await Api.post("/api/login", data);
  localStorage.setItem("access_token", `Bearer ${res.data.token}`);
  return res.data;
};
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: loginStudent,
    onSuccess: (data) => {
      dispatch(loginUser(data));
      notifySuccess("Login Successful");
      setTimeout(() => {
        navigate("/admin/index");
      }, 500);
    },
    onError: () => {
      notifyError("Wrong email or password");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get("email");
    const password = formData.get("password");

    mutate({ email, password });
    // e.target.reset(); // reset form
  };
  return (
    <>
      <Col xs="12" md="9" style={{ marginTop: "2rem" }}>
        <Row>
          <img alt={"logo"} className="navbar-brand-img m-auto" src={logo} height={"90px"} />
        </Row>

        {/* #D9FFED !important */}
        <Card
          className="bg-secondary shadow border-0  "
          style={{ marginTop: "2rem", borderRadius: "17px" }}
        >
          <CardBody className="py-lg-5 box-padding-login">
            <CardHeader className="bg-transparent pb-3 p-0">
              <div className="text-muted text-left mt-2 p-0 mb-1">
                <h2 style={{ fontSize: "2rem", color: "#255F6D" }}>Sign in </h2>
                <p style={{ color: "#255F6D80" }}>
                  Enter your email and password to login to your account
                </p>
              </div>
            </CardHeader>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup
                  className="input-group-alternative bg-white p-2 rounded-3"
                  style={{ borderRadius: "1rem" }}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    name="email"
                    placeholder="Email"
                    type="email"
                    className=""
                    autoComplete="email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup
                  className="input-group-alternative p-2 bg-white rounded-3"
                  style={{ borderRadius: "1rem" }}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText require="true">
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    name="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="current-password webauthn"
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox d-flex align-items-center justify-content-between">
                <div>
                  <input className="custom-control-input" id=" customCheckLogin" type="checkbox" />
                  <label className="custom-control-label" htmlFor=" customCheckLogin">
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>

                <Link to={"/auth/forgot-password"} className="text-underline">
                  ForgotPassword?
                </Link>
              </div>
              <div className="">
                <Button
                  className="my-4 w-100 border-0 text-dark px-3 py-3"
                  style={{ background: "#1AE584" }}
                  color="primary"
                  type="submit"
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
