import Api from "config/api";

export const GetUserDetails = async () => {
  try {
    const response = await Api.get(`/api/users/user`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error.message);
    return error.response;
  }
};

// update user details
export const UpdateUserDetails = async (data) => {
  try {
    const response = await Api.post(`/api/users/profile/update`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response) {
      // The request was made and the server responded with a non-2xx status code
      return error.response; // Re-throw the error so React Query handles it
    } else {
      // Something else went wrong (network error, etc.)
      throw new Error("An unexpected error occurred");
    }
  }
};
