import React, { useEffect, useState } from 'react';
import { Box, Typography, Modal, Grid, Stack, Checkbox, FormControlLabel, Button } from '@mui/material';
import Api from "../../config/api";
import { useDispatch } from 'react-redux';
import { notifySuccess, notifyError } from 'utilites/toastify';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: "95%", sm: "90%", md: "80%", lg: "60%", xl: "50%" }, // Responsive width based on screen size
  maxHeight: "90vh", // To make sure it doesn't overflow vertically
  overflowY: "auto", // Enable scrolling when content is too long
  bgcolor: 'background.paper',
  border: '1px solid var(--primary)',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

const PermissionsRolesModal = ({ open, handleClose, update, data, id, name }) => {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [groupedPermissions, setGroupedPermissions] = useState({});
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (open) {
      Api.get(`api/permissions/roles/${id}`)
          .then((response) => {
            setGroupedPermissions(response.data.permissions); // Grouped permissions from API
            setSelectedPermissions(data); // Set initially selected permissions based on role
          })
          .catch((err) => {
            notifyError("Failed to load permissions");
          });
    }
  }, [open, id, data]);

  const handlePermissionChange = (event) => {
    const value = event.target.name;
    setSelectedPermissions((prev) =>
        prev.includes(value)
            ? prev.filter((item) => item !== value)
            : [...prev, value]
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = {
      permissions: selectedPermissions,
      name: name,
    };

    Api.post(`api/permissions/roles/update-role/${id}`, values)
        .then(() => {
          notifySuccess("Role has been " + (update ? "Updated" : "Added"));
          handleClose();
          setSelectedPermissions([]);
        })
        .catch((err) => {
          notifyError("Failed to update role");
        });
  };

  const closeModal = () => {
    handleClose();
    setSelectedPermissions([]);
  };

  return (
      <Modal open={open} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontSize: { xs: "1.2rem", md: "1.4rem" }, // Responsive typography
                fontWeight: "700",
                marginBottom: "20px",
                textAlign: i18n.language === "en" ? "start" : "end",
              }}
          >
            {t("user.editPermission")}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* Dynamically render grouped permissions */}
              {Object.keys(groupedPermissions).map((group, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <Stack direction={"column"} sx={{ marginTop: "6px" }}>
                      <Typography variant="caption" sx={{ color: "gray", textAlign: "center" }}>
                        {group}
                      </Typography>
                      {groupedPermissions[group]?.map((permission, idx) => (
                          <FormControlLabel
                              key={idx}
                              control={
                                <Checkbox
                                    checked={selectedPermissions.includes(permission)}
                                    onChange={handlePermissionChange}
                                    name={permission}
                                />
                              }
                              label={permission}
                          />
                      ))}
                    </Stack>
                  </Grid>
              ))}
            </Grid>

            <Stack direction="row" gap={2} mt={2} justifyContent={"center"}>
              <Button type="submit" variant="contained" color="primary" sx={{ color: "#fff", width: { xs: '100%', sm: 'auto' } }}>
                {t("Submit")}
              </Button>
              <Button onClick={handleClose} variant="outlined" color="secondary" sx={{ width: { xs: '100%', sm: 'auto' } }}>
                {t("Cancel")}
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
  );
};

export default PermissionsRolesModal;
