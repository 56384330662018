import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import Api from "../../config/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { notifySuccess, notifyError } from "utilites/toastify";
import BreadCrumbs from "components/BreadCrumbs";
import "../../css/index.css";
import useUserPermissions from "hooks/useUserPermissions";

const fetchRegistrationFees = async () => {
  const res = await Api.get("api/settings/registration-fees");

  return res.data.registration_fees; // Return the actual fees value
};

const updateRegistrationFees = async (data) => {
  const res = await Api.post("api/settings/registration-fees", data);
  return res.data;
};

export default function Settings() {
  const { t, i18n } = useTranslation();
  const { mode } = useSelector((state) => state.theme);
  const [registrationFees, setRegistrationFees] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const all_permissions = useUserPermissions();

  useEffect(() => {
    if (all_permissions.includes("Get registration fees")) {
      try {
        setIsLoading(true);
        fetchRegistrationFees().then((data) => {
          setRegistrationFees(data);
        });
      } catch (error) {
        setIsLoading(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const mutation = useMutation({
    mutationFn: (newFees) => updateRegistrationFees({ registration_fees: newFees }),
    onSuccess: () => {
      notifySuccess("Registration fees updated successfully.");
    },
    onError: () => {
      notifyError("Failed to update registration fees.");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(registrationFees);
  };
  return (
    <Box minHeight="89.5vh" sx={{ padding: "36px" }}>
      {/* BreadCrumbs Navigation */}
      <BreadCrumbs pageName={t("settings.title")} />

      {/* Settings Form */}
      <Box
        sx={{
          borderRadius: "16px",
          width: "100%",
          typography: "body1",
          backgroundColor: mode == "dark" ? "rgb(31, 42, 55)" : "white",
          padding: "24px",
        }}
      >
        <Typography
          fontWeight="bold"
          variant="h6"
          sx={{ textAlign: "start", color: mode == "dark" ? "#fff" : "#000" }}
        >
          {i18n.language == "en" ? "Settings" : "الاعدادات"}
        </Typography>
        <Typography
          sx={{ color: "gray", marginBottom: "16px", marginTop: "8px", textAlign: "start" }}
          variant="subtitle2"
        >
          {i18n.language == "en" ? "Student Registration Fees" : "رسوم تسجيل الطلاب"}
        </Typography>
        <Box sx={{ width: "100%" }}>
          <form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <TextField
                label={t("settings.registration_fees.label")}
                variant="outlined"
                fullWidth
                required
                type="number"
                value={registrationFees} // Bind to state
                onChange={(e) => setRegistrationFees(e.target.value)} // Allow user input
                disabled={isLoading || !all_permissions.includes("Update registration fees")}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "10px",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>{i18n.language === "en" ? "SAR" : "ريال"}</InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "#fff",
                  backgroundColor: "#255F6D",
                  fontSize: "15px",
                  transition: ".4s",
                  padding: "5px 20px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#255F6D",
                    opacity: ".8",
                  },
                }}
                disabled={isLoading || !all_permissions.includes("Update registration fees")}
              >
                {i18n.language == "en" ? "Save" : "حفظ"}
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </Box>
  );
}
