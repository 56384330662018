import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Toaster } from "react-hot-toast";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider, createTheme } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import themeSetting from "utilites/Theme";
import "./css/index.css";

export default function App() {
  const { mode, direction } = useSelector((state) => state.theme);
  const theme1 = useMemo(() => createTheme(themeSetting(direction, mode)), [direction, mode]);

  const cacheRtl = useMemo(() => {
    if (direction === "rtl") {
      return createCache({
        key: "muirtl",
        stylisPlugins: [prefixer, rtlPlugin],
      });
    } else {
      return createCache({
        key: "muiltr",
      });
    }
  }, [direction]);

  const { logedin } = useSelector((state) => state.user);

  const tostOptions = {
    success: {
      duration: 3000,
    },
    error: {
      duration: 3000,
    },
    style: {
      fontSize: "16px",
      padding: "16px 24px",
    },
  };

  const location = useLocation();

  return (
    <>
      <Toaster
        position="top-center"
        gutter={8}
        containerStyle={{ margin: "12px" }}
        toastOptions={tostOptions}
      />
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme1}>
          <>
            {/* <BrowserRouter> */}
            <Routes>
              <Route path="/dashboard/*" element={logedin ? <AdminLayout /> : <AuthLayout />} />
              <Route path="/auth/*" element={logedin ? <AdminLayout /> : <AuthLayout />} />
              <Route path="*" element={<Navigate to="dashboard/index" replace />} />
            </Routes>
            {/* </BrowserRouter> */}
          </>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}
