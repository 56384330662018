import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ProfileTab from "components/ProfileTab/ProfileTab";
import BreadCrumbs from "components/BreadCrumbs";
import ResetPasswordTab from "components/ResetPasswordTab/ResetPasswordTab";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Profile() {
  const [value, setValue] = React.useState(0);

  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ height: "100%", padding: "40px 20px" }}>
      <BreadCrumbs pageName={"Profile"} />
      <Box sx={{ flexGrow: 1, display: "flex", height: "100%" }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab
            label={t("profile.profile")}
            {...a11yProps(0)}
            sx={{ alignItems: "flex-start", textTransform: "capitalize" }}
          />
          <Tab
            label={t("profile.reset_password")}
            {...a11yProps(1)}
            sx={{ alignItems: "flex-start", textTransform: "capitalize" }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ProfileTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ResetPasswordTab />
        </TabPanel>
      </Box>
    </Box>
  );
}

export default Profile;
