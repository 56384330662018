import { Box, Button, Input, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query';
import Api from '../../config/api';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { notifySuccess } from 'utilites/toastify';
import { notifyError } from 'utilites/toastify';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import useUserPermissions from 'hooks/useUserPermissions';
export const Transaction = async (data, direction) => {
  const Headers = {
    // Define your headers here
    "Content-Type": "application/json",
    Accept: "application/json",
    lang: `${direction == "ltr" ? "en" : "ar"}` // Add Accept-Language header for language
    // Add other headers as needed
  };
  const res = await Api.post(`api/balances/recharge`, data, { headers: Headers });
  return res.data;
};
export default function Recharge() {
  const { t } = useTranslation()
  const { direction, mode } = useSelector((state) => state.theme);
  const all_permissions = useUserPermissions()
  const { i18n } = useTranslation()
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => Transaction(data, direction),
    onSuccess: (data) => {
      notifySuccess("Transaction Successful");
    },
    onError: (err) => {
      if (err?.response?.data?.error) {
        notifyError(err?.response?.data?.error);
      } else {
        notifyError("Transaction Falied");
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const user_card = formData.get("user_card").trim();
    const amount = formData.get("amount").trim();

    mutate({ user_card, amount });
    e.target.reset(); // reset form
  };
  return (
    <Box sx={{ padding: "40px 20px 10px 20px" }}>
      {all_permissions.includes("Charge balance") && (
        <form onSubmit={handleSubmit}>
          <Stack gap={2}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box sx={{ width: "100%" }}>
                <label>{t("recharge.card")}</label>
                <TextField
                  fullWidth
                  name="user_card"
                  required
                  inputProps={{ type: "number" }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "10px", // Add border radius
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <label>{t("recharge.am")}</label>

                <TextField
                  variant="outlined"
                  required
                  name="amount"
                  fullWidth
                  inputProps={{ type: "number", min: 0 }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "10px", // Add border radius
                      },
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield", // Firefox
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none", // Chrome, Safari, Edge
                      margin: 0,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">SAR</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Stack direction={"row"} justifyContent="flex-end" >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "black",
                  backgroundColor: "white",
                  fontSize: "15px",
                  transition: ".4s",
                  padding: "5px 20px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "white",
                    opacity: ".8",
                  },
                }}
              >
                <RestartAltIcon sx={{ color: "#B3B3B3" }} />
                {i18n.language == "en" ? "Clear" : "مسح"}
              </Button>
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#255F6D",
                  fontSize: "15px",
                  transition: ".4s",
                  padding: "5px 20px",
                  textTransform: "none",
                  marginX: "1rem",
                  "&:hover": {
                    backgroundColor: "#255F6D",
                    opacity: ".8",
                  },
                }}
                disabled={isPending}
                variant="contained"
                type="submit"
              >
                {i18n.language == "en" ? "Recharge" : "شحن"}
                <ArrowRightAltIcon />
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
    </Box>
  );
}
