/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { IconButton, Button, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Navbar, Nav, Container, Media, } from "reactstrap";
import { toggleDirection, toggleMode } from "../../redux/slices/mode.slice";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getUserData } from "../../redux/slices/user.slice";

const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  const { direction, mode } = useSelector((state) => state.theme);
  const { data } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    dispatch(getUserData())
  }, [])

  const handleClick = () => {
    if (direction === 'ltr') {
      i18n.changeLanguage('ar');
      dispatch(toggleDirection('rtl'));
    } else {
      i18n.changeLanguage('en');
      dispatch(toggleDirection('ltr'));
    }
  };
  return (
    <>
      {/* <Navbar
        className={`  navbar-${mode == "light" ? "light" : "dark"}`}
        expand="md"
        id="navbar-main">
        <Container fluid>
          <Link
            className={`h4 mb-0 text-${mode == "light" ? "dark" : "white"
              } text-uppercase d-none d-lg-inline-block`}
            to="/"
          >
            {t("dash")}
          </Link>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <IconButton
              onClick={() =>
                dispatch(toggleMode(mode === "dark" ? "light" : "dark"))
              }
            >
              {mode === "dark" ? <LightModeOutlined sx={{ color: "white" }} /> : <DarkModeOutlined />}
            </IconButton>

            <Button onClick={handleClick} sx={{
              color: `${mode == "light" ? "dark" : "white"
                }`
            }}>
              {direction === "ltr" ? "Arabic" : "English"}
            </Button>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center"> */}
                    {/* <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("../../assets/img/theme/team-4-800x800.jpg")}
                      />
                    </span> */}
                    {/* <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {data?.name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle > */}
                {/* <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu> */}
              {/* </UncontrolledDropdown >
            </Nav >
          </Box >
        </Container >
      </Navbar > */}
    </>
  );
};

export default AdminNavbar;
