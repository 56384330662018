import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import routes from "routes.js";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return <Route path={prop.path} element={prop.component} key={key} exact />;
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content mt-0" ref={mainContent}>
        <AuthNavbar />
        <div className="header py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100"></div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
          </Row>
        </Container>
      </div>
      {/* <AuthFooter /> */}
    </>
  );
};

export default Auth;
