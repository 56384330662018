import { Box, Typography } from "@mui/material";
import Api from "../../config/api";
import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "components/BreadCrumbs";
import phone from "../../assets/img/icons/UI/Vector.svg";
const imgs = ["child_care.svg", "family_restroom.svg", "manage_accounts.svg", "Vector.svg"];
const Header = () => {
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [operationsDay, setOperationsDay] = useState([]);
  const [operationsMonth, setOperationsMonth] = useState([]);
  const userTypes = [
    { en: "student", ar: "طالب", name: "student" },
    { en: "parent", ar: "ولي امر", name: "parent" },
    { en: "system user", ar: "مستخدم", name: "system_user" },
  ];
  const operationsTypes = [
    { en: "Recharge", ar: "عمليات الشحن" },
    { en: "Withdrawal", ar: "عمليات السحب" },
  ];
  const { direction, mode } = useSelector((state) => state.theme);
  const { i18n } = useTranslation();
  const [date, setDate] = useState([
    {
      en: "Recharge Report",
      len: operationsDay?.recharge,
      ar: "تقرير الشحن",
      type: "Since Today",
      typeAr: "من اليوم",
    },
    {
      en: "Recharge Report",
      len: operationsMonth?.recharge,
      ar: "تقرير الشحن",
      type: "This month",
      typeAr: "هذا الشهر",
    },
    {
      en: "Deduction Report",
      len: operationsDay?.deduction,
      ar: "تقرير الاستهلاك",
      type: "Since Today",
      typeAr: "من اليوم",
    },
    {
      en: "Deduction Report",
      len: operationsMonth?.deduction,
      ar: "تقرير الاستهلاك",
      type: "This month",
      typeAr: "هذا الشهر",
    },
  ]);

  useEffect(() => {
    getUsers();
    getOperations();
  }, []);
  async function getUsers() {
    try {
      let res = await Api.get("/api/reports/users");
      let data = res.data;
      setUsers(data);
    } catch (error) {
      // console.log(error);
    }
  }
  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  async function getOperations() {
    try {
      let res = await Api.get("/api/reports/operations");
      let data = res.data;

      setDate([
        {
          en: "Recharge Report",
          len: data?.operations_count_day?.recharge,
          ar: "تقرير الشحن",
          type: "Since Today",
          typeAr: "من اليوم",
        },
        {
          en: "Recharge Report",
          len: data?.operations_count_month?.recharge,
          ar: "تقرير الشحن",
          type: "This month",
          typeAr: "هذا الشهر",
        },
        {
          en: "Deduction Report",
          len: data?.operations_count_day?.deduction,
          ar: "تقرير الاستهلاك",
          type: "Since Today",
          typeAr: "من اليوم",
        },
        {
          en: "Deduction Report",
          len: data?.operations_count_month?.deduction,
          ar: "تقرير الاستهلاك",
          type: "This month",
          typeAr: "هذا الشهر",
        },
      ]);
    } catch (error) {
      // console.log(error);
    }
  }

  return (
    <>
      <Box className="header  pb-8 " sx={{ minHeight: "90vh" }}>
        <Container fluid>
          <BreadCrumbs pageName={t("dashboard")} />
          <div
            className={`card px-5 ${mode == "dark" ? "dark-card" : ""}`}
            dir={`${i18n.language == "en" ? "ltr" : "rtl"}`}
          >
            <div className="card-body">
              <h2
                className={`card-title p-0 m-0 ${
                  i18n.language == "en" ? "text-left" : "text-right"
                }`}
              >
                {i18n.language == "en" ? "USERS STATISTICS" : "إحصائيات المستخدمين"}
              </h2>
              <p
                className={`text-muted p-0 m-0 ${
                  i18n.language == "en" ? "text-left" : "text-right"
                }`}
              >
                {i18n.language == "en"
                  ? "Data recorded at the current moment"
                  : "الداتا المحفوظه حتي الأن"}
              </p>

              <div className="row text-center mt-4 justify-content-center">
                {userTypes.map((ele, index) => {
                  let data = users.find((user) => user.type === ele.name);

                  return (
                    <div
                      className={`col-md-4 ${i18n.language === "en" ? "text-left" : "text-right"}`}
                      key={index}
                    >
                      <img
                        className="navbar-brand-img"
                        style={{ filter: "grayscale(100%)" }}
                        src={require("../../assets/img/icons/UI/" + imgs[index])}
                        width={"30px"}
                        alt={`${ele.en}`}
                      />
                      <h3 className="mt-2">{data?.number || "0"}</h3>
                      <p className="text-uppercase text-muted fw-bolder">{ele[i18n.language]}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className={`card px-5 mt-3 ${mode == "dark" ? "dark-card" : ""}`}
            dir={`${i18n.language == "en" ? "ltr" : "rtl"}`}
          >
            <div className="card-body">
              <h2
                className={`card-title p-0 m-0 ${
                  i18n.language == "en" ? "text-left" : "text-right"
                }`}
              >
                {i18n.language == "en" ? "Operations statistics" : "إحصائيات العمليات"}
              </h2>
              <p
                className={`text-muted p-0 m-0 ${
                  i18n.language == "en" ? "text-left" : "text-right"
                }`}
              >
                {i18n.language == "en"
                  ? "Recharge and deduction transactions count collected daily/monthly"
                  : "عمليات الشحن والخصم التي تتم يوميا و شهريا"}
              </p>

              <div className="row text-center mt-4 justify-content-center">
                {date?.map((ele, index) => {
                  return (
                    <div
                      className={`col-md-3 ${i18n.language == "en" ? "text-left" : "text-right"}`}
                      key={index}
                    >
                      <img className="navbar-brand-img" src={phone} style={{ width: "4.5rem" }} />
                      <h3 className="mt-2">{ele[i18n.language]}</h3>

                      <p className="text-uppercase text-muted m-0 p-0 fw-bolder">
                        {i18n.language == "en" ? ele.type : ele.typeAr}
                      </p>
                      <p className="text-uppercase text-muted fw-bolder">
                        {i18n.language === "en" ? `${ele.len || "0"} SAR` : `${ele.len || 0} ريال`}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default Header;
