import Api from "config/api";
import { useTranslation } from "react-i18next";
import { notifyError } from "utilites/toastify";

export const GetEquipment = async () => {
    const res = await Api.get("api/select-boxes/equipments");
    return res.data;
};

export const AddEquipment = async (data) => {
  const res = await Api.post("api/select-boxes/equipments", data);
  return res.data;

};

export const UpdateEquipment = async (data) => {
  const res = await Api.put(`api/select-boxes/equipments/${data.id}`, data);
  return res.data;

};

export const DeleteEquipment = async ({ id }) => {
  const res = await Api.delete(`api/select-boxes/equipments/${id}`);
  return res.data;

};
