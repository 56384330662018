import React, { useState } from "react";
import styles from "./ForgotPassword.module.css";
import { Link, useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import { notifySuccess } from "utilites/toastify";
import { CircularProgress } from "@mui/material";
import { ResetRequest } from "./api";
import { notifyError } from "utilites/toastify";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return; // Prevent form submission if email is invalid
    }

    // Reset error if email is valid
    setError("");
    setLoading(true);

    // Simulate API call to send email with OTP
    // Replace this with your actual API call

    const res = await ResetRequest(email);
    // console.log(res);

    if (res.status === 200) {
      setLoading(false);
      notifySuccess(`${res?.data?.message}`);
      setTimeout(() => {
        navigate("/auth/forgot-password/otp", { state: { email: email }, replace: true });
      }, 500);
      return;
    } else if (res?.status === 422) {
      setError(res?.data?.errors);
      setLoading(false);
      return;
    } else if (res?.status === 500) {
      notifyError("Ops something went wrong internal server error 500!");
      setLoading(false);
      return;
    } else {
      notifyError(res?.data?.message);
      setLoading(false);
      return;
    }
  };

  return (
    <div className={styles.card}>
      <h1>Reset Password</h1>
      <p className={`mt-1 mb-4 ${styles.message}`}>
        Enter your email address and we will send you an OTP to reset your password.
      </p>
      <form action="" className="mb-4" onSubmit={handelSubmit}>
        <div className="mb-3">
          <label htmlFor="user_email" className={("d-block", styles.email_label)}>
            Email
          </label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
            name="email"
            id="user_email"
            autoComplete="email"
            className={`mb-1 ${styles.user_email}`}
            placeholder="user@theqa.sa"
            disabled={loading}
            required
          />
          {error && <span className="text-danger text-sm">{error}</span>}
        </div>

        <button
          type="submit"
          disabled={loading}
          className={`py-2 px-3 text-white w-100 d-flex algin-items-center justify-content-center  ${styles.send_button}`}
        >
          {loading ? <CircularProgress size={25} /> : "Send Reset Email"}
        </button>
      </form>

      <div className="d-flex align-items-center">
        <WestIcon sx={{ color: "#020817", fontSize: "15px" }} />
        <p className={`mb-0 ${styles.login_link}`}>
          Back to{" "}
          <Link to={"/auth/login"} className={`text-underline`}>
            Login
          </Link>
        </p>
      </div>
    </div>
  );
}

export default ForgotPassword;
