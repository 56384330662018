import Api from "config/api";
import { useTranslation } from "react-i18next";
import { notifyError } from "utilites/toastify";

export const GetDeparment = async () => {
  const res = await Api.get("api/select-boxes/departments");
  return res.data;
};

export const AddDeparment = async (data) => {
  const res = await Api.post("api/select-boxes/departments", data);
  return res.data;
};

export const UpdatedDeparment = async (data) => {
  const res = await Api.put(`api/select-boxes/departments/${data.id}`, data);
  return res.data;
};

export const DeletedDeparment = async ({ id }) => {
  const res = await Api.delete(`api/select-boxes/departments/${id}`);
  return res.data;
};
