import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "../../utilites/toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AddRoles, UpdateRoles } from "../../pages/Roles/api";
import { getRoles } from "../../redux/slices/user.slice";

const RolesModal = ({ input, update, page, id, data, open, handleClose }) => {

  const { direction, mode } = useSelector((state) => state.theme);
  const dispatch = useDispatch()
  const [studentInput, setStudentInput] = useState(input);

  useEffect(() => {
    setStudentInput(input)
  }, [input]);

  const queryClient = useQueryClient();
  const { mutate: mutateAdd } = useMutation({
    mutationFn: AddRoles,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["User"],
      });
      notifySuccess("Roles Added");
      handleClose()
      dispatch(getRoles(page))
    },

    onError: (err) => {
      if (err.response) {
        // console.log(err.response);
        const status = err.response.status;
        const errorCode = err.code || "ERR_UNKNOWN";
        const errorMessage = err.message || "An unknown error occurred";

        // console.log(`Error Code: ${errorCode}`);
        // console.log(`Error Message: ${errorMessage}`);
        // console.log(`Status: ${status}`);

        if (status === 422) {
          const errors = err.response.data.errors;
          // console.log(errors);
          const errorMessages = [];

          if (errors.name) {
            errorMessages.push(`Name: ${errors.name.join(', ')}`);
          }

          errorMessages.forEach(message => {
            notifyError(message)
          });
        }
      } else {
        // console.log("Error", err.message);
      }
      // handleApiError(err)
      // notifyError("Something went wrong")
    }

  });
  const { mutate: mutateUpdate } = useMutation({
    mutationFn: UpdateRoles,
    onSuccess: (data) => {

      queryClient.invalidateQueries({
        queryKey: ["student"],
      });
      notifySuccess("Roles Updated");
      handleClose()
      dispatch(getRoles(page))
    },
    onError: (err) => {
      // console.log(err);
      notifyError("Something went wrong")
    }
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    if (studentInput.password && (studentInput.password !== studentInput.confirmPass)) { return notifyError(t("user.passErr")) }

    if (update) {
      mutateUpdate({ ...studentInput, id })
    } else {
      mutateAdd({ ...studentInput, type: "system_user" });
    }

  }
  const { t } = useTranslation();
  const theme = useTheme();

  return (

    <div style={{ textAlign: "right", display: "flex", justifyContent: "center" }}>
      <Dialog
        // fullScreen
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        sx={{
          //You can copy the code below in your theme

          "& .MuiPaper-root": {
            background: `${mode == "dark" ? "#172B4D" : "white"}`,
          },
          "& .MuiBackdrop-root": {},
        }}
        dir={direction}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: theme.palette.text.main }} >
            {update ? t("roles.edit") : t("roles.add")}
          </Typography>{" "}
          <IconButton onClick={handleClose} style={{ float: "right" }}>
            <CloseIcon color="primary"></CloseIcon>
          </IconButton>{" "}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} >
            <Grid sx={{ padding: "10px" }} container spacing={2}>
              <Grid item xs={12} >
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t("managment.name")}
                  name="name"
                  required
                  value={studentInput.name || ""}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1AE584",
                    transition: ".4s",
                    "&:hover": {
                      backgroundColor: "#1AE584",
                      opacity: ".8",
                    },
                  }}
                  variant="contained"
                  type="submit">
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RolesModal;

