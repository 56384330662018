import Api from "config/api";

export const GetParent = async (page) => {
  try {
    const res = await Api.get("api/users/parent", {
      params: {
        per_page: 10,
        page: page,
      },
    });
    return res.data;
  } catch (error) {
    // console.error("Error fetching ser data:", error);
    return error;
  }
};

export const AddParent = async (data) => {
  const res = await Api.post("api/users/new/user", data);
  return res.data;
};

export const UpdateParent = async (data) => {
  // console.log(data);
  const res = await Api.post(`api/users/user/update/${data.id}`, data);
  return res.data;
};

export const DeleteParent = async ({ id }) => {
  const res = await Api.delete(`api/users/delete/user/${id}`);
  return res.data;
};
