import Api from "config/api";

export const GetRoles = async (page) => {
  try {
    const res = await Api.get("api/roles-permissions", {
      params: {
        per_page: 10,
        page: page,
      },
    });
    return res.data;
  } catch (error) {
    // console.error("Error fetching ser data:", error);
    return error;
  }
};

export const AddRoles = async (data) => {
  const res = await Api.post("api/permissions/roles", data);
  return res.data;

};

export const UpdateRoles = async (data) => {
  const res = await Api.post(`api/permissions/roles/update-role/${data.id}`, data);
  return res.data;

};

export const DeleteRoles = async ({ id }) => {
  const res = await Api.delete(`api/permissions/roles/${id}`);
  return res.data;

};
