import { useCallback } from "react";

function useTruncateText(defaultLength = 20) {
  const truncate = useCallback(
    (text, length = defaultLength) => {
      return text?.length > length ? text?.slice(0, length) + "..." : text;
    },
    [defaultLength]
  );

  return truncate;
}

export default useTruncateText;
