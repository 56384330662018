import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UpdateUserDetails } from "./api";
import { notifySuccess } from "utilites/toastify";
import { notifyError } from "utilites/toastify";
import { useTranslation } from "react-i18next";

function ResetPasswordTab() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const theme = useTheme();
  const { t } = useTranslation();

  // handle submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const password = formData.get("password");
    const password_confirmation = formData.get("password_confirmation");

    // Check if the password is correct and are the same
    if (password !== password_confirmation) {
      setPasswordError(t("profile.passwords_do_not_match"));
      return;
    }
    // Check password length
    if (password.length < 8) {
      setPasswordError(t("profile.password_must_be_at_least_8_characters_long"));
      return;
    }

    const res = await UpdateUserDetails(formData);
    setLoading(true);
    if (res?.status === 200) {
      notifySuccess(t("profile.user_details_updated_successfully"));
    } else if (res?.status === 422) {
      // console.log(res.data.errors);
      for (const key in res.data.errors) {
        notifyError(`${key}:  ${res.data.errors[key]}`);
      }
    } else {
      console.error("Error updating profile", res);
    }

    setLoading(false);
  };
  return (
    <div>
      <Typography
        variant="h1"
        sx={{ color: theme.palette.text.main, fontSize: "45px", marginBottom: "24px" }}
      >
        {t("profile.reset_password")}
      </Typography>

      {/* Form to show user data and also to update any field */}
      <form onSubmit={handleSubmit}>
        {/* Password */}
        <FormControl sx={{ mb: 4, width: "100%", display: "block" }} variant="outlined">
          <InputLabel htmlFor="password">{t("profile.password")}</InputLabel>
          <OutlinedInput
            sx={{ width: "100%" }}
            id="password"
            type={showPassword ? "text" : "password"}
            name="password"
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            error={!!passwordError}
            disabled={loading}
          />
          {passwordError && (
            <FormHelperText error={!!passwordError} variant="outlined">
              {passwordError}
            </FormHelperText>
          )}
        </FormControl>

        {/* Password confirmation */}
        <FormControl sx={{ mb: 4, width: "100%", display: "block" }} variant="outlined">
          <InputLabel htmlFor="password_confirmation">
            {t("profile.password_confirmation")}
          </InputLabel>
          <OutlinedInput
            sx={{ width: "100%" }}
            id="password_confirmation"
            type={showPassword ? "text" : "password"}
            name="password_confirmation"
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password confirmation"
            error={!!passwordError}
            disabled={loading}
          />
          {passwordError && (
            <FormHelperText error={!!passwordError} variant="outlined">
              {passwordError}
            </FormHelperText>
          )}
        </FormControl>

        {/* Button submit */}
        <Button
          variant="contained"
          type="submit"
          sx={{ width: "100%", color: "#fff", padding: "10px 15px" }}
          disabled={loading}
        >
          {t("profile.update")}
        </Button>
      </form>
    </div>
  );
}

export default ResetPasswordTab;
